'use client'

import { useRouter } from 'next/navigation'
import React, { useContext, useEffect, useRef, useState } from 'react'
import { z } from 'zod'
import Form from '@/components/Form'
import FileService from '@/contexts/FileServiceContext'
import Toast from '@/contexts/ToastContext'
import { fetchHub } from '@/lib/hub/fetchHub'
import {
  FlowType,
  FormField,
  FormSteps,
  HubCreateFormFieldData,
  ImageCropProps,
} from '@/lib/types'
import { convertFieldsToObject } from '@/lib/utils/convertFieldsToObject'
import getCroppedImage from '@/lib/utils/cropImage'
import { formatHandleString } from '@/lib/utils/formatInputString'
import { validateField } from '@/lib/utils/validateField'
import { validateFormSchema } from '@/lib/utils/validateFormSchema'

type FormFieldNoValue = Omit<FormField, 'value'>

type ArtWorkFieldProps = FormFieldNoValue & {
  value: ImageCropProps
}

type HubCreateFormProps = {
  currentStep: number
  setCurrentStep: React.Dispatch<React.SetStateAction<number>>
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>
  setHasUnsavedChanges: React.Dispatch<React.SetStateAction<boolean>>
  isParentOpen: boolean
}

export default function HubCreateForm({
  currentStep,
  setCurrentStep,
  setIsOpen,
  setHasUnsavedChanges,
  isParentOpen,
}: HubCreateFormProps) {
  const nameFieldRef = useRef<HTMLInputElement>(null)
  const permalinkFieldRef = useRef<HTMLInputElement>(null)
  const imageFieldRef = useRef<HTMLInputElement>(null)

  const hubCreateFields = [
    {
      name: 'name',
      label: 'Name',
      type: 'text',
      placeholder: 'Enter a name for your hub',
      required: true,
      inputProps: {
        validate: (value: string) => {
          if (value?.length > 0) {
            return true
          }

          return 'Required'
        },
        inputRef: nameFieldRef,
      },
    },
    {
      name: 'permalink',
      label: 'permalink',
      placeholder: 'Enter a permalink',
      type: 'text',
      required: true,
      hint: 'ninaprotocol.com/hubs/',
      inputProps: {
        validate: (value: string) => {
          if (value?.length > 0) {
            return true
          }

          return 'Required'
        },
        inputRef: permalinkFieldRef,
      },
    },
    {
      name: 'image',
      label: 'Image',
      type: 'imageUpload',
      value: undefined,
      required: false,
      inputProps: {
        fileSizeLimit: 10000000,
        inputRef: imageFieldRef,
      },
    },
    {
      name: 'description',
      label: 'Description',
      placeholder: 'Enter a description for your hub',
      type: 'textarea',
      required: true,
      value: '',
    },
    {
      name: 'review',
      type: 'review',
      flowType: 'hub',
    },
  ] as FormField[]

  const [fields, setFields] = useState(hubCreateFields)
  const { createHub } = useContext(FileService.Context)
  const { addToast } = useContext(Toast.Context)
  const [submitting, setSubmitting] = useState(false)
  const router = useRouter()

  const formSteps: FormSteps = {
    0: {
      fields: ['name', 'permalink', 'image', 'description'],
      buttonLabel: 'Continue',
      onChangeCallback: async (fields, setFields) => {
        const validationSchema = z.object({
          //cofirm max length for both these fields
          name: z
            .string()
            .min(1, { message: 'Name is required' })
            .max(50, { message: 'Name must be less than 50 characters' }),
          permalink: z
            .string()
            .min(1, { message: 'Permalink is required' })
            .max(50, { message: 'permalink must be less than 50 characters' }),
        })

        const validationResult = validateFormSchema(
          validationSchema,
          fields,
          setFields,
        )

        if (!validationResult.success) return false

        setSubmitting(true)

        const artworkField = fields?.find(
          (field: FormField) => field.name === 'image',
        ) as ArtWorkFieldProps

        if (artworkField.value) {
          const { file, croppedAreaPixels, imageSrc } = artworkField.value
          let croppedImageResult: string | undefined

          if (imageSrc && croppedAreaPixels) {
            croppedImageResult = (await getCroppedImage(
              imageSrc,
              croppedAreaPixels,
              file?.name,
              file?.type,
            )) as string

            setFields((prevFields) => {
              const prevFieldsCopy = [...prevFields]

              const artworkField = prevFieldsCopy.find(
                (field) => field.name === 'image',
              )

              if (artworkField?.value) {
                artworkField.value.croppedImage = croppedImageResult
              }

              return prevFieldsCopy
            })
          }
        }

        const permalink = fields.find((field) => field.name === 'permalink')

        const permalinkExists = await doesPermalinkAlreadyExist(
          permalink?.value,
        )

        if (permalinkExists) {
          setFields((prevFields) => {
            const prevFieldsCopy = [...prevFields]

            const permalink = prevFieldsCopy.find(
              (field) => field.name === 'permalink',
            )

            if (permalink?.value) {
              permalink.error = ['A hub with this permalink already exists']
            }

            return prevFieldsCopy
          })
          setSubmitting(false)

          return false
        }

        setSubmitting(false)

        return validationResult.success
      },
    },
    1: {
      fields: ['review'],
      buttonLabel: 'Continue',
      onChangeCallback: async () => {
        return true
      },
    },
  }

  const doesPermalinkAlreadyExist = async (handle: string) => {
    let permalinkExists = false
    try {
      const { hub } = await fetchHub(handle)

      if (hub) {
        permalinkExists = true
      }
    } catch (error) {
      permalinkExists = false
    }

    return permalinkExists
  }

  useEffect(() => {
    if (!isParentOpen) {
      setFields(hubCreateFields)
      setCurrentStep(0)
    }
  }, [isParentOpen])

  const handleChange = async (e: React.ChangeEvent<HTMLElement>) => {
    const target = e.target as HTMLInputElement
    setHasUnsavedChanges(true)

    setFields((prevFields: FormField[]) => {
      const updatedFields = prevFields.map((field) => {
        if (field.name === target.name) {
          if (field.name === 'permalink') {
            const updatedError = validateField(field, e)

            return {
              ...field,
              hint: `ninaprotocol.com/hubs/${formatHandleString(target.value)}`,
              error: updatedError,
              value: formatHandleString(target.value),
            }
          }

          const updatedError = validateField(field, e)

          return {
            ...field,
            value: target.value,
            error: updatedError,
          }
        }

        return field
      })

      return updatedFields
    })
  }

  const handleSubmit = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    e.stopPropagation()

    try {
      const data = convertFieldsToObject(
        fields,
        FlowType.Hub,
      ) as HubCreateFormFieldData

      if (data) {
        setSubmitting(true)
        const { hub, error } = await createHub(data)

        if (error) {
          addToast({
            variant: 'error',
            message: error,
          })
          setSubmitting(false)

          return
        }

        if (hub) {
          addToast({
            variant: 'success',
            message: `Hub ${hub.data.displayName} created successfully`,
          })
          setCurrentStep(0)
          setFields(hubCreateFields)
          setIsOpen(false)
          setSubmitting(false)
          router.push(`/hubs/${hub.handle}`)

          return
        }
      }
    } catch (error) {
      setSubmitting(false)
      addToast({
        message: 'Hub creation unsuccessful. Please try again.',
        variant: 'error',
      })
    }
  }

  const handleCroppedImage = (data: object) => {
    setFields((prevFields) => {
      const prevFieldsCopy = [...prevFields]

      const artworkField = prevFieldsCopy.find(
        (field) => field.name === 'image',
      )

      if (artworkField) {
        artworkField.value = data
      }

      return prevFieldsCopy
    })
  }

  return (
    <div>
      <Form
        formSteps={formSteps}
        fields={fields}
        setFields={setFields}
        buttonLabel="Continue"
        title=""
        handleSubmit={handleSubmit}
        handleChange={handleChange}
        handleCroppedImage={handleCroppedImage}
        submittingLabel={currentStep === 0 ? 'Validating' : 'Creating Hub'}
        currentStep={currentStep}
        setCurrentStep={setCurrentStep}
        isInModal={true}
        disableSubmit={submitting}
      />
    </div>
  )
}
