import Nina from '@nina-protocol/js-sdk'
import { cache } from 'react'
import initSdk from '@/lib/utils/initSdk'
import { ContentNodeType, FetchHubResponse, Release } from '../types'

export const preloadHub = async (publicKey: string, withAccountData = true) => {
  void fetchHub(publicKey, withAccountData)
}

export const fetchHub = cache(
  async (publicKey: string, withAccountData = true) => {
    await initSdk()

    const hubResponse = await Nina.Hub.fetch(
      publicKey,
      undefined,
      withAccountData,
    )

    if (hubResponse?.releases) {
      hubResponse.releases = hubResponse.releases.map((release: Release) => {
        release.type = ContentNodeType.HubRelease

        return release
      })
    }

    return hubResponse as FetchHubResponse
  },
)
