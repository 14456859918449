import Nina from '@nina-protocol/js-sdk'
import {
  ContentNodeType,
  FetchHubsResponse,
  Hub,
  PaginationProps,
  PublicKeyString,
} from '@/lib/types'
import initSdk from '@/lib/utils/initSdk'

export const fetchHubsForRelease = async ({
  publicKey,
  pagination = { limit: 20, offset: 0, sort: 'desc' },
  withAccountData = true,
}: {
  publicKey: PublicKeyString
  pagination: PaginationProps
  withAccountData: boolean
}) => {
  try {
    await initSdk()

    const hubsResponse = await Nina.Release.fetchHubs(
      publicKey,
      pagination,
      withAccountData,
    )

    if (hubsResponse?.hubs.length > 0) {
      hubsResponse.hubs = hubsResponse?.hubs.map((hub: Hub) => {
        hub.type = ContentNodeType.Hub

        return hub
      })
    }

    return hubsResponse as FetchHubsResponse
  } catch (error) {
    console.warn(error)

    return { hubs: [] }
  }
}
